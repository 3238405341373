@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");

body {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by most modern browsers */
  font-family: "Work Sans", sans-serif;
}

p {
  text-align: center;
  font-family: "Work Sans", sans-serif;
}

/* LOGIN Page */
.login-container {
  display: flex;
  justify-content: center;
  padding: 50px;
  gap: 10vh;
  font-family: "Work Sans", sans-serif;
  font-weight: 700px;
}

.login-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-logo img {
  width: 200px;
  margin-bottom: -10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-elements {
  margin-bottom: 1rem;
}

.form-elements .freespace-button {
  background-image: linear-gradient(45deg, #f7707d, #f29173);
  color: white;
  border: none;
  width: 100%;
}

/* Vendor Login Container Styles */
.vendor-login-container {
  display: none; /* Hide vendor login container by default */
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 767px) {
  .login-container {
    flex-direction: column; /* Stack containers vertically on mobile */
  }

  /* Display vendor login container on mobile */
  .vendor-login-container {
    display: block;
    margin-top: 20px;
  }
}

/* Other Styles (unchanged for desktop) */
/* ! fs user sso login  */
.user-card {
  font-family: "Work Sans", sans-serif;
  font-weight: 200;
  width: 100%;
  max-width: 100%;
  height: fit-content;
}

.horizontal-line {
  border-bottom: 1px solid black;
  margin: 1.3rem 0px 10px 0px;
}
/* ? custom styling for MATERIAL-UI textField */
.textField label.Mui-focused {
  color: #526d82;
}

.textField .MuiInput-underline:after {
  border-bottom-color: #526d82;
}

.textField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1e253e;
}

/* SIGNUP Page (unchanged for desktop) */
.form-group-signup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  /* Apply these styles for screens with a minimum width of 768px (tablets and larger) */
  .login-container {
    flex-direction: row;
    justify-content: center;
    gap: 50vh;
  }

  .user-card {
    width: 260px;
  }

  .form-group-signup {
    flex-direction: row;
  }
}
