@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.syncfusion.com/ej2/material.css");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Work Sans", sans-serif;
  overflow-y: scroll;
}

.dashboard-parent-container {
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 15px;
}

body::-webkit-scrollbar-thumb {
  /* border-color: black; */
  border-radius: 1px;
  /* border: 1px solid rgb(0, 0, 0); */
  background-color: #9e9fa5;
}

/* * Top Navigation */

/* .user-detail-nav {
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 13px;
}
.credit {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 12px;
  width: 50%;
} */

/* #credit-balance {
  font-weight: bolder;
  color: yellow;
  font-size: 15px;
} */

/* card materials */

/* Vendor box container styles */
.vendor-box-container {
  display: flex;
  flex-direction: column;
  /* Change to column on smaller screens */
  justify-content: flex-start;
  /* Align content to the top */
  align-items: center;
  min-height: 100vh;
  /* Ensure it takes up at least the screen height */
  padding: 80px;
}

.vendorCard {
  margin-bottom: 20px;
}

#foodType {
  width: 10px;
}

#pin-pay-btn {
  background-color: #12ba29;
  color: white;
}

.payment-title {
  background-color: black;
  color: white;
  display: flex;
  justify-content: left;
}

.payment-details {
  padding: 10px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .alert-container {
  right: 0;
  position: absolute;
  margin: 20px;
}

.alert-container-dashboard {
  right: 0;
  position: absolute;
  margin-bottom: 200px;
} */

/* <Typography sx={{textAlign:'left', fontSize:'35px', color:'white', fontFamily:'Work Sans, san-serif', fontWeight:'300'}}>Payment</Typography> */
.payment-successfull {
  text-align: left;
  color: white;
}

.payments-header {
  background-color: #3a4cb4;
  color: #ffffff;
}

.payments-row {
  color: black;
  display: flex;
}

.no-padding .MuiCardContent-root {
  padding: 0 !important;
}

.cardMediaImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* For PC's or Laptops */
@media (min-width: 768px) {
  .vendors-card {
    display: flex;
    /* width: fit-content; */
    width: 100%;
  }

  #pay-btn {
    background-color: #12ba29;
    color: white;
    width: 120px;
    /* float: right; */
    float: left;
  }

  #confirm-pay-btn {
    background-color: #12ba29;
    color: white;
  }

  #download-btn {
    color: white;
    /* width: 120px; */
    /* margin-right: 100%; */
    float: right;
  }
}

/* For Mobiles */
@media screen and (max-width: 767px) {
  body {
    /* Add styles for smaller screens */
    background-size: 10px 10px;
    /* Adjust background size for smaller screens */
  }

  .vendor-card {
    flex-direction: column;
  }

  .vendor-box-container {
    /* Adjust styles for the vendor box container on smaller screens */
    padding: 50px;
    /* Increase padding for better spacing */
  }

  .vendorCard {
    /* Adjust styles for vendor cards on smaller screens */
    margin-bottom: 20px;
    /* Increase margin for better spacing */
  }

  .cardMediaImage {
    object-fit: cover;
    box-sizing: border-box;
  }
  /* Add more specific styles for smaller screens here */
  #pay-btn {
    margin-bottom: 10px;
  }
  #pay-btn,
  #confirm-pay-btn {
    background-color: #12ba29;
    color: white;
    width: 100%;
  }

  .credit-updater {
    flex-direction: column;
  }
}
