.skeleton-parent{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.skeleton-image{
    width: 50%;
}

.skeleton-name{
    padding: 50px;
    width: 30%;
}
.skeleton-box-container{
    border-radius: 8px;
    background-color: #F5F5F5;
}

@media screen and (max-width: 767px) {

    .skeleton-parent{
        flex-direction: column;
    }

    .skeleton-image{
        width: 100%;
    }

    .skeleton-name{
        width: 50%;
        padding: 0;
    }
}