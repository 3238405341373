/* * Top Navigation */
.top-navigation-bar {
  height: 50px;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  position: sticky;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  justify-content: space-around;
}

.user-detail-nav {

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 13px;
}

.credit {
  color: white;
  font-size: 12px;
}

#credit-balance {
  font-weight: bolder;
  color: yellow;
  font-size: 15px;
}

#popper-user-name,
#profile-pop-logout {
  display: none;
}

@media screen and (max-width: 767px) {

  #logout {
    display: none;
  }

  #profile-pop-logout {
    display: flex;
    align-items: center;
  }
}

.user-detail-nav:hover {
  cursor: pointer;
}