@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900;&display=swap");

input[type="number"] {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  -moz-appearance: textfield; /* Firefox */
}

/* Hide the increase/decrease spinner for number inputs in webkit-based browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableHeader {
  color: black;
  font-weight: 600;
  font-size: 16px;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  color: black;
  font-weight: bolder;
  font-size: 16px;
}

.credit-update {
  border: 1px solid black;
  border-radius: 3px;
  width: 100%;
}

.decision-btn {
  background-color: black;
}

.alert-container {
  right: 0;
  position: absolute;
  margin: 20px;
}

.credit-updater {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  gap: 5px;
  align-items: center;
}

.credit-updater input {
  width: fit-content;
  padding: 5px;
}

/* style={{ paddingBottom: '10px', paddingLeft: 'calc((100% - 80%)/2)' }} */
.credit-manage-back-btn {
  padding-bottom: 10px;
  padding-left: calc((100% - 80%) / 2);
}

@media screen and (max-width: 600px) {
  .credit-manage-back-btn {
    padding: 0px 0px 10px 10px;
  }
  .credit-updater{
    width: 100%;
    padding: 10px 0px 10px 10px;
    justify-content: center;
    margin: auto;
  }
}
