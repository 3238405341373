.transaction-history-form-controller{
    display: flex;
    padding: 65px 0px 15px 0px;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    width: 80%;
    margin: auto;
}

.history-table{
    margin: 10px auto;
}

@media screen and (max-width: 600px) {
    .transaction-history-form-controller{
        width: 100%;
        padding: 65px 10px 15px 10px;
    }
}